// 加入企业
// mxy
<template>
  <div class="main">
    <a-form class="user-layout-login" :model="formState">
      <a-tabs
        v-model:activeKey="activeKey"
        :tabBarStyle="{ textAlign: 'center', borderBottom: 'unset' }"
        @change="change"
      >
        <a-tab-pane key="tab1" tab="加入企业">
          <a-form-item :wrapper-col="{ span: 24 }">
            <a-input-search
              placeholder="搜索名称，企业Id"
              size="large"
              v-model:value="value"
              :maxLength="60"
              type="text"
              @search="onSearch"
            />
          </a-form-item>
          <a-spin :spinning="spinning">
            <a-form-item class="center" :wrapper-col="{ span: 24 }">
              <router-link to="/account/Create">
                <div class="description">
                  <a-empty v-if="total === 0" :description="description" />
                </div>
              </router-link>
              <div
                class="card flex-row"
                :key="index"
                v-for="(item, index) in obj.data"
              >
                <img
                  v-if="item.enterpriseLogo !== null"
                  class="logo"
                  :src="item.enterpriseLogo"
                />
                <img
                  v-if="item.enterpriseLogo === null"
                  class="logo"
                  src="@/assets/icon_company_weishangchang.svg"
                />
                <div class="describe">
                  <div class="ellipsisTitle" :title="item.enterpriseName">
                    {{ item.enterpriseName }}
                  </div>
                  <div class="frontColor">
                    <div class="ellipsis" aria-disabled="">
                      ID：{{ item.enterpriseCode }}
                    </div>
                    <div class="ellipsis" :title="item.industryName">
                      行业：{{ item.industryName }}
                    </div>
                  </div>
                </div>
                <a-button
                  :class="
                    item.joined == false
                      ? 'jionBtn align-center'
                      : 'disabledButton'
                  "
                  type="primary"
                  @click="jion(item)"
                  :disabled="item.joined == false ? false : true"
                  >{{ item.joined == false ? "加入" : "已加入" }}</a-button
                >
              </div>
            </a-form-item>
          </a-spin>

          <a-form-item class="bottom" :wrapper-col="{ span: 24 }">
            <a-pagination
              hideOnSinglePage
              v-model:current="pageCount"
              v-model:pageSize="pageSize"
              :total="total"
              @change="pageChange"
            />
          </a-form-item>
          <!-- <a-form-item class="bottom" v-if="total === 0"  :wrapper-col="{ span: 24 }">
            <router-link to='/account/Create'>
                <span>暂无企业？点击创建</span>
            </router-link>
        </a-form-item> -->
        </a-tab-pane>
        <a-tab-pane key="tab2" tab="切换企业">
          <!-- <a-menu class="menu"> -->
          <a-spin :spinning="spinning">
            <a-form-item class="center" :wrapper-col="{ span: 24 }">
              <a-empty
                v-if="tenantsList.tenantList.length === 0"
                :description="tenantList"
              />
              <div class="select">
                <div
                  :key="index"
                  v-for="(item, index) in tenantsList.tenantList"
                  class="item flex-row justify-between align-center"
                >
                  <div class="flex-column">
                    <div :title="item.enterpriseName">
                      {{ item.enterpriseName }}
                    </div>
                    <div v-if="item.isCreator">我创建的</div>
                    <div v-if="!item.isCreator">我加入的</div>
                  </div>

                  <div class="now" v-if="item.isLookUp">当前企业</div>
                  <a-button
                    type="primary"
                    size="small"
                    class="btn"
                    v-if="!item.isLookUp"
                    @click="changeTenant(item)"
                  >
                    切换
                  </a-button>
                </div>
              </div>
            </a-form-item>
            <div class="on_trial" @click="trial">试用体验版本，立即体验~</div>
          </a-spin>

          <!-- </a-menu> -->
        </a-tab-pane>
      </a-tabs>
    </a-form>
  </div>
</template>

<script>
import api from '@/api/API'
import { getTenantList } from '@/api/IdentityAPI'
export default {
  name: 'account-register',
  data () {
    return {
      tenantsListEmpty: false,
      tenantList: '暂无数据',
      spinning: false,
      activeKey: 'tab1',
      disabled: true,
      externalGenreList: [],
      register: {
        name: '',
        position: '',
        address: '',
        unit: '',
      }, // 账户手机
      // phone: '',
      // countDowns: null,
      // checkCode: '',
      // buttonVisable: true,
      // timer: null,
      formState: {
        userName: '',
        password: '',
      },
      state: {
        loginBtn: false,
        // login type: 0 email, 1 username, 2 telephone
        loginType: 0,
        smsSendBtn: false,
      },
      industryId: '',
      industryIdName: '',
      searchInput: '',
      obj: {},
      joined: '',
      joinedText: '未加入',
      total: 0,
      page: 1,
      pageSize: 10,
      pageCount: 1,
      description: '暂无企业,点击创建',
      row: {},
      tenantsList: {},
      value: '',
    }
  },
  created () {
    getTenantList().then(data => {
      this.tenantsList = data
      // if (this.tenantsList.tenantList.length === 0) {
      //   this.tenantsListEmpty = true
      // }
    })
  },
  methods: {
    // 试用
    trial () {
      this.$router.push({ path: '/account/Trial' })
    },
    change (activeKey) {
      this.total = 0
      this.value = ''
      this.obj = {}
      if (activeKey === 'tab2') {
        this.spinning = true
        getTenantList().then(data => {
          this.tenantsList = data
          // if (this.tenantsList.tenantList.length === 0) {
          //   this.tenantsListEmpty = true
          // }
          this.spinning = false
        })
          .catch(err => {
            this.spinning = false
            console.log(err)
            // this.$message.error('数据获取失败' + err)
          })
      }
    },
    // 切换企业
    changeTenant (e) {
      setTimeout(() => {
        this.$message.loading('正在切换企业')
      }, 200)
      api
        .post('/api/app/account/switch-tenant/{' + e.tenantId + '}')
        .then(({ data }) => {
          if (data.isBan) {
            this.$message.error('当前企业已被禁用，无法切换')
            return
          }
          this.$store.dispatch('account/setToken', data.rawData)
          this.$store.dispatch('account/setTenant', {
            tenantId: data.tenantId,
          })
          location.reload()
          window.location.href = '/WorkManagement/WorkBench'
        })
        .catch(err => {
          // this.$message.error('切换租户失败')
          console.log(err)
        })
      this.$emit('clearSelectKey')
    },
    pageChange (page) {
      this.page = page
      this.getSearchData()
    },
    onSearch (value) {
      this.page = 1
      this.pageCount = 1
      this.searchInput = value
      if (this.searchInput === '') {
        this.obj = {}
        this.total = 0
        return
      }
      this.getSearchData()
    },
    getSearchData () {
      this.spinning = true
      api
        .get('/api/app/sys-enterprise-info/enterprise-info-list-by-search', {
          params: {
            searchInput: this.searchInput,
            pageSize: this.pageSize,
            pagination: this.page,
          },
        })
        .then(({ data }) => {
          this.obj = data
          this.row = data.data
          this.total = data.total
          this.spinning = false
        })
        .catch(err => {
          this.spinning = false
          this.state.loginBtn = false
          console.log(err)
          // this.$message.error('数据获取失败' + err)
        })
    },
    // 点击加入
    jion (value) {
      this.spinning = true
      const obj = {
        tenantId: value.tenantId,
      }
      api
        .post('/api/app/ent-apply-join/ent-apply-join', obj)
        .then(({ data }) => {
          this.spinning = false
          this.$message.success(data)
          this.getSearchData()
        })
        .catch(err => {
          this.spinning = false
          console.log(err)
          // this.$message.error('加入失败' + err)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/common.scss";
.main {
  padding: 30px;
  .ant-form-item-control-wrapper {
    width: 100% !important;
    height: 100px;
  }

  .user-layout-login {
    .center {
      // height: 320px;
      overflow-x: hidden;
      overflow-y: auto;
      .ant-empty {
        margin: 95px 8px;
      }
    }
    .center::-webkit-scrollbar {
      width: 10px;
      height: 100%;
    }
    .center::-webkit-scrollbar-track {
      background: rgb(239, 239, 239);
      border-radius: 2px;
    }
    .center::-webkit-scrollbar-thumb {
      background: #bfbfbf;
      border-radius: 10px;
    }
    .center::-webkit-scrollbar-thumb:hover {
      background: rgb(197, 197, 197);
    }
    .ellipsisTitle {
      width: 100%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    .ellipsis {
      width: 60%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    .right {
      margin-left: 10px;
    }
    .logo {
      width: 50px;
      height: 50px;
      // background-color: #134CCF;
    }
    .describe {
      width: 70%;
      margin-left: 10px;

      .frontColor {
        color: #555;
      }
    }
    .state {
      width: 66px;
      height: 26px;
      opacity: 1;
      line-height: 26px;
      text-align: center;
      border: 1px solid #e6002d;
      color: #e6002d;
      border-radius: 3px;
      background-color: #ffe3e7;
      position: relative;
      margin-top: 6px;
    }
    .jionBtn {
      position: relative;
      top: 80px;
      right: 10px;
    }
    .disabledButton {
      position: relative;
      top: 80px;
      right: 10px;
    }
    label {
      font-size: 14px;
    }
    .getCaptcha {
      display: block;
      width: 100%;
      height: 40px;
    }

    .forge-password {
      font-size: 14px;
    }

    button.login-button {
      padding: 0 15px;
      font-size: 16px;
      height: 40px;
      width: 100%;
    }

    .user-login-other {
      text-align: left;
      margin-top: 24px;
      line-height: 22px;

      .item-icon {
        font-size: 24px;
        color: rgba(0, 0, 0, 0.2);
        margin-left: 16px;
        vertical-align: middle;
        cursor: pointer;
        transition: color 0.3s;
        &:hover {
          color: #1890ff;
        }
      }
      .register {
        float: right;
      }
    }
  }
  .card {
    height: 130px;
    padding: 10px 0;
    border-bottom: 1px solid #dddddd;
  }
  .btnGetCheckCode {
    border: 0px;
    background-color: #fff;
    color: blue;
  }
  .bottom {
    margin-top: -24px;
    text-align: left;
    font-size: 12px;
  }
  .ant-pagination {
    margin-top: 20px;
    float: right;
  }
}
.on_trial {
  color: #134ccf;
  cursor: pointer;
  text-align: center;
}
.btn {
  width: 66px;
  height: 28px;
  padding: 3px 5px;
  background-color: #134ccf;
}
.select {
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 400px;
  /*修改滚动条样式*/
  .title {
    font-size: 18px;
    // padding: 10px 15px 10px 15px;
  }
  .item {
    border-bottom: 1px solid #f2f1ef;
    padding: 15px;
    .now {
      padding: 3px 5px;
      background: #e7ecf8;
      color: #004dca;
    }
    .name {
      display: inline-block;
      max-width: 180px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
.select::-webkit-scrollbar {
  width: 10px;
  /**/
}
.select::-webkit-scrollbar-track {
  background: rgb(239, 239, 239);
  border-radius: 2px;
}
.select::-webkit-scrollbar-thumb {
  background: #bfbfbf;
  border-radius: 10px;
}
.select::-webkit-scrollbar-thumb:hover {
  background: rgb(197, 197, 197);
}
::v-deep(.jionBtn.ant-btn) {
  background-color: #134ccf;
  color: #ffff;
}
::v-deep(.jionBtn.ant-btn:hover) {
  background-color: #134ccf;
  opacity: 0.7;
  color: #ffff;
}
::v-deep(.btn.ant-btn:hover) {
  background-color: #134ccf;
  opacity: 0.7;
  color: #ffff;
}
::v-deep(.description.ant-empty-description) {
  color: #134ccf;
}
::v-deep(.ant-tabs-tab:hover) {
  color: #134ccf;
}
::v-deep(.ant-tabs-nav .ant-tabs-tab-active) {
  color: #134ccf;
}
::v-deep(.ant-tabs-nav .ant-tabs-tab:active) {
  color: #134ccf;
}
::v-deep(.ant-tabs-ink-bar) {
  background-color: #134ccf;
}
::v-deep(.ant-input-affix-wrapper:hover, .ant-input-affix-wrapper:focus) {
  border-color: #134ccf;
}
::v-deep(.ant-input-affix-wrapper-focused) {
  border-color: #134ccf;
  box-shadow: none;
}
</style>
